import { useEffect } from 'react'
import {
  GetStaticPathsResult,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next'
import { contentfulApi, segmentApi } from '@root/api'
import { DownloadAppPage } from '@root/experiences/DownloadApp'
import { DownloadAppData } from '@root/types/DownloadApp'
import { MetaTags } from '@root/layouts'
import { saveImageToBuildDist } from '@root/experiences/DownloadApp/utils/saveImageToBuildDist'
import { ENV } from '@root/config'

export async function getStaticPaths(): Promise<GetStaticPathsResult> {
  try {
    const { items } =
      await contentfulApi.getEntriesByContentType('downloadAppPage')

    const paths = items.map((item) => {
      return {
        params: { key: item.fields.key as string },
      }
    })

    return {
      paths,
      fallback: false,
    }
  } catch (error: any) {
    // Temp fix as we don't have entries yet on master
    const errorJson = JSON.parse(error.message)

    const contentTypeNotFound =
      errorJson.status === 400 &&
      errorJson.details.errors[0].name === 'unknownContentType'

    if (contentTypeNotFound) {
      return {
        paths: [],
        fallback: false,
      }
    }

    throw new Error(`🚫 Error running getStaticPaths() during SSG: ${error}`)
  }
}

export async function getStaticProps({
  params,
}: GetStaticPropsContext<{ key: string }>): Promise<
  GetStaticPropsResult<{ data: DownloadAppData | undefined }>
> {
  try {
    const { items } =
      await contentfulApi.getEntriesByContentType('downloadAppPage')

    const flattened = items.map((item) => {
      return {
        entry_id: item.sys.id,
        key: item.fields.key,
        name: item.fields.name,
        ...item.fields,
      }
    }) as DownloadAppData[]

    const data = flattened.find((entry) => entry.key === params?.key)

    const heroImage = data?.image?.fields?.file
    const qrCodeImage = data?.qrCodeImage?.fields?.file
    const comparisonImageDesktop = data?.comparisonImageDesktop?.fields?.file
    const comparisonImageMobile = data?.comparisonImageMobile?.fields?.file

    if (ENV.CACHE_CTF_DOWNLOAD_APP_ASSETS === 'true' && ENV.NEXT_BUILD_ID) {
      if (heroImage?.url && heroImage?.fileName) {
        await saveImageToBuildDist({
          imageSrc: 'https:' + heroImage.url,
          imageName: heroImage.fileName,
        }).then((result) => {
          console.log(result)
        })
      }
      if (qrCodeImage?.url && qrCodeImage?.fileName) {
        await saveImageToBuildDist({
          imageSrc: 'https:' + qrCodeImage.url,
          imageName: qrCodeImage.fileName,
        }).then((result) => {
          console.log(result)
        })
      }
      if (comparisonImageDesktop?.url && comparisonImageDesktop?.fileName) {
        await saveImageToBuildDist({
          imageSrc: 'https:' + comparisonImageDesktop.url,
          imageName: comparisonImageDesktop.fileName,
        }).then((result) => {
          console.log(result)
        })
      }
      if (comparisonImageMobile?.url && comparisonImageMobile?.fileName) {
        await saveImageToBuildDist({
          imageSrc: 'https:' + comparisonImageMobile.url,
          imageName: comparisonImageMobile.fileName,
        }).then((result) => {
          console.log(result)
        })
      }
    }

    if (!data) {
      throw new Error(`No valid data found for the key ${params?.key}`)
    }

    return { props: { data } }
  } catch (error) {
    throw new Error(`🚫 Error running getStaticProps() during SSG: ${error}`)
  }
}

interface DownloadAppPageProps {
  data: DownloadAppData
}

const Page = ({ data }: DownloadAppPageProps): JSX.Element => {
  useEffect(() => {
    segmentApi.page()
  }, [])

  const { metaTitle, metaDescription, description } = data

  return (
    <>
      <MetaTags
        title={metaTitle}
        description={metaDescription || description}
        category="Onboarding"
      />
      <DownloadAppPage data={data} />
    </>
  )
}

export default Page
